<template>
  <div id="employee-edit-general-data-tab">

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="Marca" v-model="vehicle.brand" data-vv-as="Marca" v-validate.initial="'required'" name="brand" />
              <span class="text-danger text-sm" v-show="errors.has('brand')">{{ errors.first('brand') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="Modelo" v-model="vehicle.model" data-vv-as="Modelo" v-validate.initial="'required'" name="model" />
              <span class="text-danger text-sm" v-show="errors.has('model')">{{ errors.first('model') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="Cor" v-model="vehicle.color" data-vv-as="Cor" v-validate.initial="'required'" name="color" />
              <span class="text-danger text-sm" v-show="errors.has('color')">{{ errors.first('color') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Tipo</label>
              <v-select v-model="vehicle.type" :reduce="option => option.value"
                :clearable="false" :options="[
                  {value: 'CAR', label: 'Automóvel'},
                  {value: 'MOTORCYCLE', label: 'Moto'},
                  {value: 'TRUCK', label: 'Caminhão'},
                  {value: 'BUS', label: 'Ônibus'}
                ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input type="number" class="w-full mt-0" label="Ano Fabricação" v-model="vehicle.year_of_manufacture" data-vv-as="Ano Fabricação" v-validate.initial="'required'" name="year_of_manufacture" />
              <span class="text-danger text-sm" v-show="errors.has('year_of_manufacture')">{{ errors.first('year_of_manufacture') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input type="number" class="w-full mt-0" label="Ano do Modelo" v-model="vehicle.year_of_model" data-vv-as="Ano do Modelo" v-validate.initial="'required'" name="year_of_model" />
              <span class="text-danger text-sm" v-show="errors.has('year_of_model')">{{ errors.first('year_of_model') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="Placa" v-model="vehicle.license_plate" data-vv-as="Placa" v-validate.initial="'required'" name="license_plate" />
              <span class="text-danger text-sm" v-show="errors.has('license_plate')">{{ errors.first('license_plate') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="RENAVAM" v-model="vehicle.renavam" data-vv-as="RENAVAM" v-validate.initial="'required'" name="renavam" />
              <span class="text-danger text-sm" v-show="errors.has('renavam')">{{ errors.first('renavam') }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Categoria</label>
              <v-select v-model="vehicle.category" :reduce="option => option.value" data-vv-as="Categoria" v-validate.initial="'required'" name="category"
                :clearable="false" :options="[
                  {value: 'A',   label: 'A'},
                  {value: 'B',   label: 'B'},
                  {value: 'C',   label: 'C'},
                  {value: 'D',   label: 'D'},
                  {value: 'E',   label: 'E'},
                  {value: 'ACC', label: 'ACC'},
                  {value: 'X',   label: 'X'}
                ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('category')">{{ errors.first('category') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Vcmto IPVA</label>
              <v-select class="vue_select_drop_size_190" v-model="vehicle.ipva_expiration" :reduce="option => option.value"
                :clearable="false" :options="[
                  {value: 1,  label: 'JANEIRO'},
                  {value: 2,  label: 'FEVEREIRO'},
                  {value: 3,  label: 'MARÇO'},
                  {value: 4,  label: 'ABRIL'},
                  {value: 5,  label: 'MAIO'},
                  {value: 6,  label: 'JUNHO'},
                  {value: 7,  label: 'JULHO'},
                  {value: 8,  label: 'AGOSTO'},
                  {value: 9,  label: 'SETEMBRO'},
                  {value: 10, label: 'OUTUBRO'},
                  {value: 11, label: 'NOVEMBRO'},
                  {value: 12, label: 'DEZEMBRO'}
                ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Adaptado</label>
              <v-select v-model="vehicle.adapted" :reduce="option => option.value"
                :clearable="false" :options="[
                  {value: false,  label: 'NÃO'},
                  {value: true,  label: 'SIM'},
                ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Vincular Instrutor(es)</label>
              <v-select appendToBody v-model="vehicle.employees" multiple :reduce="option => option.value" :options="employees" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Ident. na agenda</label> <br>
          <input class="w-4/5" v-model="vehicle.identification_color" type="color">
        </div>

        <div class="vx-col md:4/5 w-full">
          <vs-input class="w-full mt-2" label="Observações" v-model="vehicle.observation" />
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mb-6 mt-2" @click="save" :disabled="!validateForm">Alterar</vs-button>
          </div>
        </div>
      </div>
  </div>

</template>

<script>
import vSelect from 'vue-select'

import moduleEmployee from '@/store/employee/moduleEmployee.js'

export default {
  components: {
    vSelect
  },
  props: {
    vehicle: { type: Object,  required: true }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    validateForm () {
      return !this.errors.any()
    }
  },
  data () {
    return {
      localShow: false
    }
  },

  methods: {
    async save () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_vehicle')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      try {
        this.$vs.loading()
        await this.$store.dispatch('vehicle/update', this.vehicle)
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },

  created () {
    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })
  }

}
</script>

<style>
  .vue_select_drop_size_190 .vs__dropdown-menu {
    max-height: 190px;
  }
</style>
