var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-alert",
        {
          attrs: {
            color: "danger",
            title: "Veículo não encontrado!",
            active: _vm.register_not_found,
          },
          on: {
            "update:active": function ($event) {
              _vm.register_not_found = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "O veículo com código: " +
                _vm._s(_vm.$route.params.vehicleId) +
                " não foi encontrado. "
            ),
          ]),
          _c(
            "span",
            [
              _c("span", [_vm._v("Verifique ")]),
              _c(
                "router-link",
                {
                  staticClass: "text-inherit underline",
                  attrs: { to: { name: "vehicles" } },
                },
                [_vm._v("a lista de veículos.")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.ready && !_vm.register_not_found
        ? _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "tabs-container px-6 pt-6",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "vs-tabs",
                  {
                    staticClass: "tab-action-btn-fill-conatiner",
                    model: {
                      value: _vm.activeTab,
                      callback: function ($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab",
                    },
                  },
                  [
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Dados Gerais",
                          "icon-pack": "feather",
                          icon: "icon-user",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("general-data", {
                              ref: "general_data",
                              staticClass: "mt-4",
                              attrs: { vehicle: _vm.vehicle },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Manutenção",
                          "icon-pack": "feather",
                          icon: "icon-tool",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("vehicle-maintenance", {
                              staticClass: "mt-4",
                              attrs: { vehicle: _vm.vehicle },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Abastecimento",
                          "icon-pack": "feather",
                          icon: "icon-file-text",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("fuel-supply", {
                              staticClass: "mt-4",
                              attrs: { vehicle: _vm.vehicle },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Agenda",
                          "icon-pack": "feather",
                          icon: "icon-calendar",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("schedule", {
                              staticClass: "mt-4",
                              attrs: { vehicle: _vm.vehicle },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "OS",
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("vehicle-service-order", {
                              staticClass: "mt-4",
                              attrs: { vehicle: _vm.vehicle },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }