var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data
    ? _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _vm.status === "Não Concluído"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary",
              })
            : _vm.status === "Concluído"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 bg-success",
              })
            : _c("feather-icon", {
                staticClass: "text-danger mr-1",
                attrs: { icon: "SlashIcon", svgClasses: "w-5 h-5" },
              }),
          _c(
            "span",
            {
              staticClass: "cursor-default ml-1 mr-2",
              class: `text-${
                _vm.status === "Concluído"
                  ? "success"
                  : _vm.status === "Não Concluído"
                  ? "secondary"
                  : "danger"
              }`,
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm._f("scheduleStatus")(_vm.status)) + "\n  "
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }