<template>
  <div>
    <vs-alert color="danger" title="Veículo não encontrado!" :active.sync="register_not_found">
      <span>O veículo com código: {{ $route.params.vehicleId }} não foi encontrado. </span>
      <span>
        <span>Verifique </span>
        <router-link :to="{ name:'vehicles' }" class="text-inherit underline">a lista de veículos.</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="ready && !register_not_found">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Dados Gerais" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <general-data class="mt-4" :vehicle="vehicle" ref="general_data" />
            </div>
          </vs-tab>
          <vs-tab label="Manutenção" icon-pack="feather" icon="icon-tool">
            <div class="tab-text">
              <vehicle-maintenance class="mt-4" :vehicle="vehicle" />
            </div>
          </vs-tab>
          <vs-tab label="Abastecimento" icon-pack="feather" icon="icon-file-text">
            <div class="tab-text">
              <fuel-supply class="mt-4" :vehicle="vehicle" />
            </div>
          </vs-tab>
          <vs-tab label="Agenda" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">
              <schedule class="mt-4" :vehicle="vehicle" />
            </div>
          </vs-tab>
          <vs-tab label="OS" icon-pack="feather" icon="icon-dollar-sign">
            <div class="tab-text">
              <vehicle-service-order class="mt-4" :vehicle="vehicle" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moduleVehicle        from '@/store/vehicle/moduleVehicle.js'

import GeneralData          from './tabs/GeneralData.vue'
import VehicleMaintenance   from './tabs/VehicleMaintenance/VehicleMaintenance.vue'
import FuelSupply           from './tabs/FuelSupply/FuelSupply.vue'
import Schedule             from './tabs/Schedule/Schedule.vue'
import VehicleServiceOrder  from './tabs/VehicleServiceOrder/VehicleServiceOrder.vue'

export default {
  components: {
    GeneralData,
    VehicleMaintenance,
    FuelSupply,
    Schedule,
    VehicleServiceOrder
  },
  data () {
    return {
      vehicle: {},
      ready: false,
      register_not_found: false,
      activeTab: 0
    }
  },
  created () {
    if (!moduleVehicle.isRegistered) {
      this.$store.registerModule('vehicle', moduleVehicle)
      moduleVehicle.isRegistered = true
    }
    this.$vs.loading()
    this.$store.dispatch('vehicle/fetchVehicle', this.$route.params.vehicleId)
      .then((response) => {
        if (!response.data.id) {
          this.register_not_found = true
        }
        this.ready = true
        this.activeTab = 0
        this.$vs.loading.close()
        this.vehicle = JSON.parse(JSON.stringify(this.$store.state.vehicle.vehicle))
        this.vehicle.adapted = !!this.vehicle.adapted
        this.vehicle.employees = this.vehicle.employees.map(a => a.id)
      })
      .catch(err => {
        console.error(err)
        this.$vs.loading.close()
      })

  }
}
</script>

<style>

</style>
