<template>
  <div id="tab-fuelSupply">
    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <vs-popup class="popup65" :fullscreen="false" title="Relatório de Consumo" :active.sync="popupFuelSupplyReport">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <vs-input type="date" @change="checkPeriod" v-model="firstPeriod" label="Data Inicial" />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <vs-input type="date" @change="checkPeriod" v-model="lastPeriod" label="Data Final" />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="vs-input--label">Tipo</label>
          <v-select :clearable="false" v-model="type" :reduce="option => option.value"
            :options="[
              {value: 'PDF', label: 'PDF'},
              {value: 'EXCEL', label: 'EXCEL'},
            ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>
      <span class="text-danger text-sm" v-show="periodError">{{ periodErrorMessage }}</span>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupFuelSupplyReport = false">Cancelar</vs-button>
            <vs-button class="ml-4" @click="generateFuelSupplyReport" :disabled="periodError">Gerar Documento</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

    <new-registration-modal :vehicle="vehicle" :show="popupNewRegistration" title="Cadastrar Abastecimento"
      @action="refreshGrid(false)"
      @cancel="popupNewRegistration = false"></new-registration-modal>

    <edit-registration-modal :vehicle="vehicle" :show="popupEditRegistration" title="Alterar Abastecimento"
      @action="refreshInfiniteCacheGrid(false)"
      @cancel="popupEditRegistration = false"></edit-registration-modal>

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca por responsável..." />

          <vx-tooltip text="Cadastrar Manutenção" position="top" class="ml-1 mb-4 md:mb-0">
            <feather-icon @click="handleNewRegistration" icon="PlusCircleIcon" svgClasses="h-8 w-8 cursor-pointer hover:text-primary"></feather-icon>
          </vx-tooltip>

          <vx-tooltip text="Relatório de Consumo" position="top" class="ml-2 mb-4 md:mb-0">
            <feather-icon @click="printFuelReport" icon="PrinterIcon" svgClasses="h-8 w-8 cursor-pointer hover:text-primary"></feather-icon>
          </vx-tooltip>

      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="payload.limit"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
const newRegistrationModal = () => import('./newRegistrationModal.vue')
const editRegistrationModal = () => import('./editRegistrationModal.vue')
import formatDateTime from '@/util/formatDateTime.js'
import moment from 'moment'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'
// Cell Renderer
import CellRendererActions      from './cell-renderer/CellRendererActions.vue'

import moduleFuelSupply from '@/store/fuel-supply/moduleFuelSupply.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererActions,
    newRegistrationModal,
    editRegistrationModal,
    vSelect
  },
  props: {
    vehicle: {
      Object, required: true
    }
  },

  data () {
    return {
      popupNewRegistration: false,
      popupEditRegistration: false,
      popupFuelSupplyReport: false,

      showPdfTitle: 'Relatório de Consumo.',
      srcPdf: '',
      popupShowPdf: false,

      periodErrorMessage: 'O período é obrigatório.',
      periodError: true,
      firstPeriod: '',
      lastPeriod: '',
      type: 'PDF',

      searchQuery: '',

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true,
        vehicle_id: this.vehicle.id
      },

      // AgGrid
      localeText: null,
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Data',
          field: 'date_of_supply',
          valueFormatter: params => {
            return params.value && formatDateTime(params.value)
          },
          width: 130
        },
        {
          headerName: 'Responsável',
          field: 'employee.name',
          width: 200
        },
        {
          headerName: 'Fornecedor',
          field: 'provider.name',
          width: 200
        },
        {
          headerName: 'Combustível',
          field: 'type_of_fuel',
          width: 150
        },
        {
          headerName: 'KM',
          field: 'current_mileage',
          width: 100
        },
        {
          headerName: 'Litros',
          field: 'liters',
          width: 100
        },
        {
          headerName: 'Total',
          field: 'value_total',
          valueFormatter: params => {
            return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'
          },
          width: 130
        },
        {
          headerName: 'Ações',
          field: 'actions',
          width: 95,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            edit: this.edit, // usado para passar parametros para dentro o componente renderizado no ag grid
            delete: this.confirmDelete // usado para passar parametros para dentro o componente renderizado no ag grid
          }
        }
      ],
      components: {
        CellRendererActions
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    fuelSupply () {
      return this.$store.state.fuelSupply.fuelSupply
    },
    allData () {
      return this.$store.state.fuelSupply.fuelSupplysGrid
    }
  },
  methods: {
    formatDateTime (dateTimeString, type = null) {
      console.log(dateTimeString)
      return formatDateTime(dateTimeString, type)
    },
    printFuelReport () {
      this.firstPeriod = ''
      this.lastPeriod = ''
      this.type = 'PDF'

      this.popupFuelSupplyReport = true
    },
    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc (blob) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Relatório.xlsx'
      link.click()
    },
    async generateFuelSupplyReport () {
      this.$vs.loading()
      try {
        const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/fuel/supply/report`, {
          firstPeriod: this.firstPeriod,
          lastPeriod: this.lastPeriod,
          orderBy: 'date_of_supply',
          format: this.type,
          vehicles: [this.vehicle.id]
        }, { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null })

        if (this.type === 'PDF') {
          this.srcPdf = `data:application/pdf;base64,${resp.data}`
          this.popupShowPdf = true
        } else if (this.type === 'EXCEL') {
          const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          this.makeBlobDoc(blob)
        }
        this.popupFuelSupplyReport = false

        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: error,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    checkPeriod () {
      if (moment(this.firstPeriod).year() > 1000 && moment(this.lastPeriod).year() > 1000) {
        const difDays = moment(this.lastPeriod).diff(moment(this.firstPeriod), 'days')
        if (difDays < 0) {
          this.periodError = true
          this.periodErrorMessage = 'O período inicial deve ser anterior ao final.'
          return
        } else if (difDays > ((365 * 5) + 1)) {
          this.periodError = true
          this.periodErrorMessage = 'O período não pode ser maior que 5 anos.'
          return
        }
        this.periodError = false
      } else if (!this.firstPeriod || !this.lastPeriod) {
        this.periodError = true
        this.periodErrorMessage = 'O período é obrigatório.'
      }
    },

    edit (data) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_vehicle_supply')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$store.commit('fuelSupply/SET', data.data)
      this.popupEditRegistration = true
    },
    confirmDelete (data) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_vehicle_supply')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$store.commit('fuelSupply/SET', data.data)
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir este abastecimento? Suas transações financeiras serão mantidas!',
        accept: this.delete,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    delete () {
      this.$store.dispatch('fuelSupply/delete', this.fuelSupply.id)
        .then(()   => {
          this.refreshGrid()
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'SUCESSO',
        text: 'Dados Excluídos',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    },
    refreshGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.setDatasource(this.dataSource)
      // this.gridApi.refreshInfiniteCache() // REMOVI ESSE PQ QDO NÃO HÁ NENHUM REGISTRO NÃO ATUALIZA AO CADASTRAR O PRIMEIRO
    },
    refreshInfiniteCacheGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },
    handleNewRegistration () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_vehicle_supply')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.popupNewRegistration = true
    },
    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('fuelSupply/fetchGrid', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return data
    }
  },
  mounted () {
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('allData')
    if (!moduleFuelSupply.isRegistered) {
      this.$store.registerModule('fuelSupply', moduleFuelSupply)
      moduleFuelSupply.isRegistered = true
    }
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
  }
}
</script>

<style>
  .vs-tooltip {
    z-index: 99999;
  }
  .noOverflow .vs-popup--content {
    overflow: hidden;
  }
  .popup80 .vs-popup {
    width: 80% !important;
  }
  .popup50 .vs-popup {
    width: 50% !important;
  }
  .popup65 .vs-popup {
    width: 65% !important;
  }
</style>
