var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-edit-general-data-tab" } },
    [
      _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
        _c("div", { staticClass: "w-full" }, [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Marca",
                    "data-vv-as": "Marca",
                    name: "brand",
                  },
                  model: {
                    value: _vm.vehicle.brand,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "brand", $$v)
                    },
                    expression: "vehicle.brand",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("brand"),
                        expression: "errors.has('brand')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("brand")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Modelo",
                    "data-vv-as": "Modelo",
                    name: "model",
                  },
                  model: {
                    value: _vm.vehicle.model,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "model", $$v)
                    },
                    expression: "vehicle.model",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("model"),
                        expression: "errors.has('model')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("model")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: { label: "Cor", "data-vv-as": "Cor", name: "color" },
                  model: {
                    value: _vm.vehicle.color,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "color", $$v)
                    },
                    expression: "vehicle.color",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("color"),
                        expression: "errors.has('color')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("color")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { value: "CAR", label: "Automóvel" },
                      { value: "MOTORCYCLE", label: "Moto" },
                      { value: "TRUCK", label: "Caminhão" },
                      { value: "BUS", label: "Ônibus" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n                Desculpe, nenhum resultado encontrado.\n              "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.vehicle.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "type", $$v)
                    },
                    expression: "vehicle.type",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
        _c("div", { staticClass: "w-full" }, [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "number",
                    label: "Ano Fabricação",
                    "data-vv-as": "Ano Fabricação",
                    name: "year_of_manufacture",
                  },
                  model: {
                    value: _vm.vehicle.year_of_manufacture,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "year_of_manufacture", $$v)
                    },
                    expression: "vehicle.year_of_manufacture",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("year_of_manufacture"),
                        expression: "errors.has('year_of_manufacture')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("year_of_manufacture")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "number",
                    label: "Ano do Modelo",
                    "data-vv-as": "Ano do Modelo",
                    name: "year_of_model",
                  },
                  model: {
                    value: _vm.vehicle.year_of_model,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "year_of_model", $$v)
                    },
                    expression: "vehicle.year_of_model",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("year_of_model"),
                        expression: "errors.has('year_of_model')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("year_of_model")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Placa",
                    "data-vv-as": "Placa",
                    name: "license_plate",
                  },
                  model: {
                    value: _vm.vehicle.license_plate,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "license_plate", $$v)
                    },
                    expression: "vehicle.license_plate",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("license_plate"),
                        expression: "errors.has('license_plate')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("license_plate")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "RENAVAM",
                    "data-vv-as": "RENAVAM",
                    name: "renavam",
                  },
                  model: {
                    value: _vm.vehicle.renavam,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "renavam", $$v)
                    },
                    expression: "vehicle.renavam",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("renavam"),
                        expression: "errors.has('renavam')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("renavam")))]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
        _c("div", { staticClass: "w-full" }, [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  attrs: {
                    reduce: (option) => option.value,
                    "data-vv-as": "Categoria",
                    name: "category",
                    clearable: false,
                    options: [
                      { value: "A", label: "A" },
                      { value: "B", label: "B" },
                      { value: "C", label: "C" },
                      { value: "D", label: "D" },
                      { value: "E", label: "E" },
                      { value: "ACC", label: "ACC" },
                      { value: "X", label: "X" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n                Desculpe, nenhum resultado encontrado.\n              "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.vehicle.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "category", $$v)
                    },
                    expression: "vehicle.category",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("category"),
                        expression: "errors.has('category')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("category")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Vcmto IPVA"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_190",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { value: 1, label: "JANEIRO" },
                      { value: 2, label: "FEVEREIRO" },
                      { value: 3, label: "MARÇO" },
                      { value: 4, label: "ABRIL" },
                      { value: 5, label: "MAIO" },
                      { value: 6, label: "JUNHO" },
                      { value: 7, label: "JULHO" },
                      { value: 8, label: "AGOSTO" },
                      { value: 9, label: "SETEMBRO" },
                      { value: 10, label: "OUTUBRO" },
                      { value: 11, label: "NOVEMBRO" },
                      { value: 12, label: "DEZEMBRO" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n                Desculpe, nenhum resultado encontrado.\n              "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.vehicle.ipva_expiration,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "ipva_expiration", $$v)
                    },
                    expression: "vehicle.ipva_expiration",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Adaptado"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { value: false, label: "NÃO" },
                      { value: true, label: "SIM" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n                Desculpe, nenhum resultado encontrado.\n              "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.vehicle.adapted,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "adapted", $$v)
                    },
                    expression: "vehicle.adapted",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Vincular Instrutor(es)"),
                ]),
                _c("v-select", {
                  attrs: {
                    appendToBody: "",
                    multiple: "",
                    reduce: (option) => option.value,
                    options: _vm.employees,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n                Desculpe, nenhum resultado encontrado.\n              "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.vehicle.employees,
                    callback: function ($$v) {
                      _vm.$set(_vm.vehicle, "employees", $$v)
                    },
                    expression: "vehicle.employees",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
        _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v("Ident. na agenda"),
          ]),
          _c("br"),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.vehicle.identification_color,
                expression: "vehicle.identification_color",
              },
            ],
            staticClass: "w-4/5",
            attrs: { type: "color" },
            domProps: { value: _vm.vehicle.identification_color },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.vehicle,
                  "identification_color",
                  $event.target.value
                )
              },
            },
          }),
        ]),
        _c(
          "div",
          { staticClass: "vx-col md:4/5 w-full" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-2",
              attrs: { label: "Observações" },
              model: {
                value: _vm.vehicle.observation,
                callback: function ($$v) {
                  _vm.$set(_vm.vehicle, "observation", $$v)
                },
                expression: "vehicle.observation",
              },
            }),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto mb-6 mt-2",
                  attrs: { disabled: !_vm.validateForm },
                  on: { click: _vm.save },
                },
                [_vm._v("Alterar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }