<template>
  <div v-if="params.data" class="flex items-center">
    <div v-if="status === 'Não Concluído'" class="h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary"></div>
    <div v-else-if="status === 'Concluído'" class="h-4 w-4 inline-block rounded-full mr-2 bg-success"></div>
    <feather-icon v-else class="text-danger mr-1" icon="SlashIcon" svgClasses="w-5 h-5" />
    <span class="cursor-default ml-1 mr-2" :class="`text-${status === 'Concluído' ? 'success' : (status === 'Não Concluído' ? 'secondary' : 'danger')}`">
      {{ status | scheduleStatus }}
    </span>

    <!-- <vx-tooltip text="Alterar Situação" position="top" class="flex items-center cursor-pointer hover:text-primary">
      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-1" />
        <vs-dropdown-menu class="w-60">

          <vs-dropdown-item @click="updateStatus('Concluído')">
            <span class="flex items-center">
              <div class="h-4 w-4 inline-block rounded-full mr-3 bg-success"></div>
              <span>Concluído</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item @click="updateStatus('Não Concluído')">
            <span class="flex items-center">
              <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
              <span>Não Concluído</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item @click="updateStatus('ABSENCE')">
            <span class="flex items-center">
              <feather-icon class="mr-2 text-danger" icon="SlashIcon" svgClasses="w-5 h-5" />
              <span class="text-danger hover:text-primary">Ausente</span>
            </span>
          </vs-dropdown-item>

        </vs-dropdown-menu>
      </vs-dropdown>
    </vx-tooltip> -->
  </div>
</template>

<script>
export default {
  name: 'CellRendererSituation',
  computed: {
    status () {
      return this.params.value
    },
    type () {
      return this.params.data.type
    }
  },
  methods: {
    updateStatus (status) {
      this.params.updateStatus(this.params, status)// assim chamo a função de fora
    }
  }
}
</script>
