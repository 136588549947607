<template>
  <div id="tab-employee">
    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca por instrutor..." />

          <!-- <vx-tooltip text="Cadastrar Manutenção" position="top" class="ml-1 mb-4 md:mb-0">
            <feather-icon @click="handleNewRegistration" icon="PlusCircleIcon" svgClasses="h-8 w-8 cursor-pointer hover:text-primary"></feather-icon>
          </vx-tooltip>

          <vx-tooltip text="Gerenciar Serviços de Manutenção" position="top" class="ml-2 mb-4 md:mb-0">
            <feather-icon icon="PrinterIcon" svgClasses="h-8 w-8 cursor-pointer hover:text-primary"></feather-icon>
          </vx-tooltip> -->

      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="payload.limit"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import formatDateTime from '@/util/formatDateTime.js'

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'
import CellRendererDateTime from './cell-renderer/CellRendererDateTime.vue'
import CellRendererSituation from './cell-renderer/CellRendererSituation.vue'

import moduleSchedule from '@/store/schedule/moduleSchedule.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererDateTime,
    CellRendererSituation
  },
  props: {
    vehicle: {
      Object, required: true
    }
  },

  data () {
    return {
      searchQuery: '',

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true,
        vehicle_id: this.vehicle.id
      },

      // AgGrid
      localeText: null,
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Data',
          field: 'start_date',
          width: 220,
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Instrutor',
          field: 'employee.name',
          width: 245
        },
        {
          headerName: 'Aluno',
          field: 'student.name',
          width: 245
        },
        {
          headerName: 'Situação',
          field: 'status',
          cellRendererFramework: 'CellRendererSituation',
          width: 200
        }
      ],
      components: {
        CellRendererDateTime,
        CellRendererSituation
      }
    }
  },
  computed: {
    allData () {
      return this.$store.state.schedule.vehicleSchedulesGrid
    }
  },
  methods: {
    formatDateTime (dateTimeString, type = null) {
      return formatDateTime(dateTimeString, type)
    },
    refreshGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.setDatasource(this.dataSource)
      // this.gridApi.refreshInfiniteCache() // REMOVI ESSE PQ QDO NÃO HÁ NENHUM REGISTRO NÃO ATUALIZA AO CADASTRAR O PRIMEIRO
    },
    refreshInfiniteCacheGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },
    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('schedule/fetchVehicleGrid', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return data
    }
  },
  mounted () {
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('allData')
    if (!moduleSchedule.isRegistered) {
      this.$store.registerModule('schedule', moduleSchedule)
      moduleSchedule.isRegistered = true
    }
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
  }
}
</script>

<style>
  .vs-tooltip {
    z-index: 99999;
  }
</style>
